import React from "react"

import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import sales_exec from "../banner/static/sales_executive.webp"
import * as classes from "./sales_executive_training.module.scss"
import loadable from "@loadable/component"
const Banner = loadable(() => import("../banner/banner"), {
  fallback: <div></div>,
})
export default function SalesExecutive() {
  return (
    <React.Fragment>
      <MainHeader />
      <Banner title={"Sales Executive Training"} backgroundImage={sales_exec} />
      <div className={classes.text_div}>
        <h2>Sales Executive Training</h2>
        <p>
          It is easy to walk blindly into a sales call with no preparation and
          planning. In fact, it is something that happens very often. Most of
          the time this leads to looking unprofessional, lazy and unprepared.
          Which if your role is selling is not something you want attached to
          yourself. “Failure to prepare, is preparing to fail” is a quote used
          when entering an important sales call.
          <br />
          There are many effective ways of planning and preparing sales. Not
          only does it involve research of the customer's company but also
          preparation of how you want the call to go. Sales Executive training
          leads to professional selling techniques and learning sales management
          techniques.
        </p>

        <p>Following module’s we are training learners for:</p>
        <ul>
          <li>Market & Customer Knowledge</li>
          <li>Negotiation and Closing Sales</li>
          <li>Product & Service Knowledge</li>
          <li>Digital Marketing and Tools</li>
          <li>Customer Engagement & Need Analysis</li>
          <li>Leadership, Collaboration and Teamwork</li>
        </ul>
      </div>
      <MainFooter />
    </React.Fragment>
  )
}
